import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ConfigurationService, GetEnvironmentConfigurationResponse, Oauth2Config } from '@patron-administration/api';
import { Environment } from '@patron-administration/api/model/environment';

const TESTING_ENV_REGEX: RegExp = /^DEV|TEST$/;

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: Oauth2Config;
  private environment: Environment;


  constructor(
    private httpClient: HttpClient,
    private configurationService: ConfigurationService,
  ) {
  }

  loadAppConfig() {
    return this.configurationService.getEnvironmentConfiguration().pipe(
      tap((config: GetEnvironmentConfigurationResponse) => {
        this.appConfig = config?.oAuth;
        this.environment = config?.environment;
      }),
    );
  }

  getConfig(): Oauth2Config {
    return this.appConfig;
  }

  getEnvironment(): Environment {
    return this.environment;
  }

  isTestingModeEnabled(): boolean {
    return TESTING_ENV_REGEX.test(this.environment?.environment);
  }

  isSKCountry(): boolean {
    return this.environment?.country === 'SK';
  }

  isCZCountry(): boolean {
    return this.environment?.country === 'CZ';
  }

  isPLCountry(): boolean {
    return this.environment?.country === 'PL';
  }
}
