import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import { ProfileSettingService } from '../../service/profile-setting.service';

@Component({
  selector: 'app-chatbot-editor-settings',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSave()">
      <div class="form-group">
        <label for="finalBlockColor" class="label">Color for final blocks (node)</label>
        <input nbInput class="d-block" id="finalBlockColor" cpOutputFormat="hex" [colorPicker]="form.get('finalBlockColor').value" (colorPickerChange)="form.get('finalBlockColor').setValue($event)" formControlName="finalBlockColor" [style.background]="form.get('finalBlockColor').value"/>
      </div>

      <div class="form-group">
        <label for="firstBlockColor" class="label">Color for initial blocks (node)</label>
        <input nbInput class="d-block" id="firstBlockColor" cpOutputFormat="hex" [colorPicker]="form.get('firstBlockColor').value" (colorPickerChange)="form.get('firstBlockColor').setValue($event)" formControlName="firstBlockColor" [style.background]="form.get('firstBlockColor').value"/>
      </div>
      <div class="form-group">
        <label for="middleBlockColor" class="label">Color for all other blocks (node)</label>
        <input nbInput class="d-block" id="middleBlockColor" cpOutputFormat="hex" [colorPicker]="form.get('middleBlockColor').value" (colorPickerChange)="form.get('middleBlockColor').setValue($event)" formControlName="middleBlockColor" [style.background]="form.get('middleBlockColor').value"/>
      </div>
      <div class="form-group">
        <label for="textEdgeColor" class="label">Color for text answers (edge)</label>
        <input nbInput class="d-block" id="textEdgeColor" cpOutputFormat="hex" [colorPicker]="form.get('textEdgeColor').value" (colorPickerChange)="form.get('textEdgeColor').setValue($event)" formControlName="textEdgeColor" [style.background]="form.get('textEdgeColor').value"/>
      </div>
      <div class="form-group">
        <label for="choiceEdgeColor" class="label">Color for choice answers (edge)</label>
        <input nbInput class="d-block" id="choiceEdgeColor" cpOutputFormat="hex" [colorPicker]="form.get('choiceEdgeColor').value" (colorPickerChange)="form.get('choiceEdgeColor').setValue($event)" formControlName="choiceEdgeColor" [style.background]="form.get('choiceEdgeColor').value"/>
      </div>
      <div class="form-group">
        <label for="fileEdgeColor" class="label">Color fo file answers (edge)</label>
        <input nbInput class="d-block" id="fileEdgeColor" cpOutputFormat="hex" [colorPicker]="form.get('fileEdgeColor').value" (colorPickerChange)="form.get('fileEdgeColor').setValue($event)" formControlName="fileEdgeColor" [style.background]="form.get('fileEdgeColor').value"/>
      </div>
      <div class="form-group">
        <label for="ratingEdgeColor" class="label">Color for raing answers (edge)</label>
        <input nbInput class="d-block" id="ratingEdgeColor" cpOutputFormat="hex" [colorPicker]="form.get('ratingEdgeColor').value" (colorPickerChange)="form.get('ratingEdgeColor').setValue($event)" formControlName="ratingEdgeColor" [style.background]="form.get('ratingEdgeColor').value"/>
      </div>
      <hr>
      <button nbButton status="primary" [disabled]="!form.valid" type="submit">Save chatbot settings</button>
    </form>
  `,
  styles: [``]
})
export class ChatbotEditorSettingsComponent implements OnInit {

  form: FormGroup;

  @Input()
  windowRef: NbWindowRef;

  constructor(
    private formBuilder: FormBuilder,
    private profileSettingService: ProfileSettingService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      finalBlockColor: [this.profileSettingService.finalBlockColor, [Validators.required]],
      firstBlockColor: [this.profileSettingService.firstBlockColor, [Validators.required]],
      middleBlockColor: [this.profileSettingService.middleBlockColor, [Validators.required]],
      textEdgeColor: [this.profileSettingService.textEdgeColor, [Validators.required]],
      choiceEdgeColor: [this.profileSettingService.choiceEdgeColor, [Validators.required]],
      fileEdgeColor: [this.profileSettingService.fileEdgeColor, [Validators.required]],
      ratingEdgeColor: [this.profileSettingService.ratingEdgeColor, [Validators.required]],
    });
  }

  onSave() {
    this.profileSettingService.setChatbotSettings(
      this.form.value.finalBlockColor,
      this.form.value.firstBlockColor,
      this.form.value.middleBlockColor,
      this.form.value.textEdgeColor,
      this.form.value.choiceEdgeColor,
      this.form.value.fileEdgeColor,
      this.form.value.ratingEdgeColor,
    )
    this.toastrService.success('New chatbot settings have been applied', 'Chatbot settings');
    this.windowRef?.close();
  }
}
