import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { AppConfigService } from '../../../app-config.service';
import { FirebaseStorageService } from '../../../common/service/firebase-storage.service';
import { GetAdminInfoResponse, UserService } from '@patron-administration/api';
import { MENU_ITEM_LOGOUT, MENU_ITEM_SETTINGS } from '../../../app.component';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';

  userMenu: NbMenuItem[] = [{title: MENU_ITEM_SETTINGS, icon: 'settings-2-outline'}, {title: MENU_ITEM_LOGOUT, icon: 'power-outline'}];
  appName = 'Patron administration';

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private rippleService: RippleService,
              private appConfigService: AppConfigService,
              private userService: UserService,
              private storageService: FirebaseStorageService,
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.setAppName();

    this.userService.getAdminInfo().pipe(
      switchMap((info: GetAdminInfoResponse) => {
        return this.storageService.getImage(`avatars/${info?.id}`, false).pipe(
          tap((imageUrl: string) => {
            this.user = {
              name: `${info.firstName} ${info.lastName}`,
              picture: imageUrl
            };
          })
        )
      })
    ).subscribe();

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  private setAppName(): void {
    const country = this.appConfigService.getEnvironment()?.country;
    const env = this.appConfigService.getEnvironment()?.environment;

    if (country && env) {
      this.appName = `${this.appName} (${env} ${country})`;
    } else if (country) {
      this.appName = `${this.appName} (${country})`;
    } else if (env) {
      this.appName = `${this.appName} (${env})`;
    }
  }
}
