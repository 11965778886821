interface String {
  format(...replacements: any[]): string;
}

if (!String.prototype.format) {
  String.prototype.format = function () {
    const args = arguments;
    return this.replace(/{(\d+)}/g, (match, index) => typeof args[index] !== 'undefined'
      ? args[index]
      : match);
  };
}

interface Array<T> {
  equals(array: Array<T>): boolean;
}

// Warn if overriding existing method
if (Array.prototype.equals)
  console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");

Array.prototype.equals = function (array) {
  if (!array) {
    return false;
  }

  if (array === this) {
    return true;
  }

  if (this.length != array.length) {
    return false;
  }

  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false;
      }
    } else if (this[i] != array[i]) {
      return false;
    }
  }
  return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});
