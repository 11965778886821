import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  selector: 'app-settings',
  template: `
    <nb-tabset fullWidth>
      <nb-tab tabTitle="Table settings">
        <app-table-settings [windowRef]="windowRef"></app-table-settings>
      </nb-tab>
      <nb-tab tabTitle="Image settings">
        <app-image-compression-settings [windowRef]="windowRef"></app-image-compression-settings>
      </nb-tab>
      <nb-tab tabTitle="Chatbot settings">
        <app-chatbot-editor-settings [windowRef]="windowRef"></app-chatbot-editor-settings>
      </nb-tab>
    </nb-tabset>
  `,
})
export class SettingsComponent {
  constructor(protected windowRef: NbWindowRef) {
  }
}
