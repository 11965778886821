import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: false,

  SERVICE_BASE_URL: '',

  firebaseConfig: {
    apiKey: "AIzaSyBkhynourqri2lKUjpwVO5ioR3hwJCcQog",
    authDomain: "financni-antivir.firebaseapp.com",
    databaseURL: "https://financni-antivir.firebaseio.com",
    projectId: "financni-antivir",
    storageBucket: "financni-antivir.appspot.com",
    messagingSenderId: "585242714189",
    appId: "1:585242714189:web:b9986ffbe1711ee159fc04",
    measurementId: "G-0WXRS2R4S9"
  }
};
