import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SortDirection, ThreatMutationOverview } from '@patron-administration/api';
import { UntypedFormArray } from '@angular/forms';
import { QuillModules } from 'ngx-quill';
import { DataUrl } from 'ngx-image-compress';
import * as moment from 'moment';
import { ISortConfig } from 'angular2-smart-table/lib/lib/data-source/data-source';

const UUIDRegex = "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}";
const singleUUIDRegex = new RegExp(`^${UUIDRegex}$`);
const whiteSpaceSeparatedUUIDsRegex = new RegExp(`^(${UUIDRegex})(\\s+${UUIDRegex})*$`);
const commaSeparatedUUIDsRegex = new RegExp(`^(${UUIDRegex})(,${UUIDRegex})*$`);
const fileUrlRegex = new RegExp('^.*\\/(.*)\\.(.*?)(\\?.*)*$');
const colorRegex = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

export interface KeyValuePair {
  key: any;
  value: any;
}

export interface UUIDsParsingResult {
  uuids: string[],
  uniqueUuids: string[],
  valid: boolean,
  note: string,
}

export function validHexColor(color: string): boolean {
  if (color) {
    return colorRegex.test(color);
  }

  return false;
}

export function validUUID(uuid: string): boolean {
  if (uuid) {
    return singleUUIDRegex.test(uuid);
  }

  return false;
}

export function parseUUIDsFromString(uuidsString: string): UUIDsParsingResult {
  let valid = false;
  let note: string = undefined;
  let uuids: string[] = undefined;
  let uniqueUuids: string[] = undefined;

  if (!uuidsString) {
    note = 'Cannot parse empty string.'
  } else {
    if (whiteSpaceSeparatedUUIDsRegex.test(uuidsString)) {
      valid = true;
      uuids = uuidsString.split(/\s+/);
      uniqueUuids = [...new Set(uuids)];
    } else if (commaSeparatedUUIDsRegex.test(uuidsString)) {
      valid = true;
      uuids = uuidsString.split(',');
      uniqueUuids = [...new Set(uuids)];
    } else {
      note = 'Enter only whitespace OR comma separated UUIDs.'
    }
  }

  return {
    uuids,
    uniqueUuids,
    valid,
    note
  }
}

export function getOnlyAsciiString(str: string): string {
  if (str) {
    const normalizedString = str.normalize('NFD');
    return normalizedString.replace(/[^\x00-\x7F]/g, '');
  }

  return str;
}

export function firstLetterUpperCaseString(s?: string): string {
  if (typeof s === 'string' && s.length >= 1) {
    return s[0].toUpperCase() + s.slice(1).toLowerCase();
  }
  return '';
}

export function getActiveMutationsWithCurrentMutation(allMutations: ThreatMutationOverview[],
                                                      recordMutationId: string): ThreatMutationOverview[] {

  const activeMutWithCurrentMut = allMutations?.filter(m => m?.active === true);
  if (recordMutationId) {
    const ind = allMutations.findIndex(m => m?.id === recordMutationId);
    if (ind >= 0 && allMutations[ind]?.active !== true) {
      activeMutWithCurrentMut.push(allMutations[ind]);
    }
  }
  return activeMutWithCurrentMut;
}

export function readFileAsObservable(file: StorageFile): Observable<FileRef> {
  return from(new Promise<any>((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = resolve;
    fr.readAsDataURL(file?.file);
  })).pipe(
    map((event: any) => {
      return {
        fileName: file?.name,
        dataURL: event?.target?.result,
      };
    })
  );
}

export function prepareStorageFiles(fileUrls: string[]): StorageFile[] {
  const files: StorageFile[] = fileUrls.map(fileUrl => prepareStorageFile(fileUrl));
  return files;
}

export function prepareStorageFile(fileUrl: string): StorageFile {
  let file: StorageFile;
  if (fileUrl) {
    const match = fileUrl.match(fileUrlRegex);
    if (match && match.length >= 3) {
      file = {
        fullPath: fileUrl,
        name: `${match[1]}.${match[2]}`,
      };
    } else {
      file = {
        fullPath: fileUrl,
        name: fileUrl.replace(/^.*[\\\/]/, ''),
      };
    }
  }

  return file;
}

export interface StorageFile {
  file?: File;
  fullPath?: string;
  storagePath?: string;
  name: string;
  dataURL?: DataUrl;
  bytesBefore?: number;
  bytesAfter?: number;
}

export interface FileRef {
  dataURL: DataUrl;
  fileName: string;
}

export interface IdNameValue {
  id: string;
  name: string;
}

export const EMPTY_ID_NAME_VALUE: IdNameValue = {
  id: null,
  name: ' - ',
};

export function setNullToAllEmptyStringsInObject(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  Object.keys(obj).forEach(k => {
    const param = obj[k];
    if (typeof param === 'string' && !param.trim().length) {
      obj[k] = null;
    }
  });
  return obj;
}

export function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_, v]) => v != null && v !== "")
    .reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
}

export function moveControl(index: number, controls: UntypedFormArray, up: boolean) {
  if (index >= 0 && controls) {
    const field = controls.at(index);
    controls.removeAt(index);
    let newIndex;
    if (up) {
      newIndex = index !== 0 ? index - 1 : controls.length;
    } else {
      newIndex = index !== controls.length ? index + 1 : 0;
    }

    controls.insert(newIndex, field);
  }
}

export function getFileExtension(fileName: string) {
  if (fileName) {
    const dotSplitName = fileName.split('.');
    if (dotSplitName.length > 1) {
      return dotSplitName[dotSplitName.length - 1];
    }
  }

  return '';
}

// Quill default simple configuration
export const QuillConfiguration: QuillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [],
    [],
    [],
    [{color: []}, {}],
    [],
    [],
  ],
}

export function convertToBoolean(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();
    return val === 'true' || val === '';
  }

  return !!val;
}

export function formatDateTime(dateString: any) {
  if (dateString) {
    const date = moment(dateString);
    return date.format('DD.MM.YYYY HH:mm');
  }
  return undefined;
}

export function getTableSorting(sortConfig: Array<ISortConfig>): TableSorting {
  if (sortConfig && sortConfig.length >= 1 && sortConfig[0]?.direction) {
    return {
      sortProperty: sortConfig[0].field,
      sortDirection: SortDirection.ASC === sortConfig[0].direction.toUpperCase() ?
        SortDirection.ASC : SortDirection.DESC,
    }
  }

  return undefined;
}

export interface TableSorting {
  sortProperty: string,
  sortDirection: SortDirection
}

export interface MultiselectItem {
  id: string;
  itemName: string;
}
