import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { GitInfoService } from '../service/git-info.service';

const X_VERSION_HEADER_KEY = 'X-Version';

@Injectable({
  providedIn: 'root'
})
export class GitInfoInterceptor implements HttpInterceptor {

  constructor(private gitInfoService: GitInfoService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const resp: HttpResponse<any> = event;
          const headers = resp.headers;
          const xVersion = headers.get(X_VERSION_HEADER_KEY);
          if (xVersion) {
            this.gitInfoService.updateGitInfo(xVersion);
          }
        }
      }
    ));
  }
}
