import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { from, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

import { UtilsRestService } from './utils-rest.service';
import { ErrorMethod, SuccessMethod } from './service.model';
import { ref, uploadString } from "firebase/storage";
import { UploadResult } from '@firebase/storage';
import { getDownloadURL, StorageReference } from '@angular/fire/storage';
import { DataUrl } from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {
  private cachedUrl = {};

  constructor(
    private afAuth: AngularFireAuth,
    private angularFireStorage: AngularFireStorage,
    private utilsRestService: UtilsRestService,
    private toastrService: NbToastrService,
  ) {
  }

  getImage(imageUrl: string, showError = true): Observable<string> {
    const cachedUrl = this.cachedUrl[imageUrl];
    if (cachedUrl) {
      return of(cachedUrl);
    }
    return this.getDownloadUrl(imageUrl, showError);
  }

  uploadImageToDirectory(directory: string, image: File): Observable<any> {
    return this.uploadImageWithFullPath(directory + image.name, image);
  }

  uploadImageWithFullPath(fullPath: string, image: File): Observable<any> {
    const uploadTask = this.angularFireStorage.upload(fullPath, image, {
      cacheControl: 'private,max-age=300',
    });

    return from(uploadTask).pipe(
      tap(() => this.utilsRestService.handleSuccess(undefined, undefined, SuccessMethod.UPLOAD, image.name)),
      catchError(err => this.utilsRestService.handleError(err, undefined, ErrorMethod.UPLOAD, image.name)),
    );
  }

  uploadDataUrlWithFullPath(fullPath: string, dataURL: DataUrl): Observable<UploadResult> {
    const fileRef = ref(this.angularFireStorage.storage, fullPath);
    const uploadTask = uploadString(fileRef, dataURL, 'data_url');

    return from(uploadTask).pipe(
      tap(() => this.utilsRestService.handleSuccess(undefined, undefined, SuccessMethod.UPLOAD, fullPath)),
      catchError(err => this.utilsRestService.handleError(err, undefined, ErrorMethod.UPLOAD, fullPath)),
    );
  }

  getDownloadUrlFromRef(ref: StorageReference): Observable<string> {
    const url = getDownloadURL(ref);
    return from(url).pipe(
      catchError(err => {
        this.toastrService.danger(`Could not get download URL for '${ref?.fullPath}'.`, 'Firebase storage');
        return of(null);
      }),
    );
  }

  deleteImages(imageUrls: string[]) {
    if (imageUrls && imageUrls.length > 0) {
      imageUrls.forEach(i => this.deleteImage(i));
    }
  }

  deleteImage(imageUrl: string): void {
    if (imageUrl) {
      if (imageUrl.startsWith("https")) {
        this.angularFireStorage.refFromURL(imageUrl).delete().subscribe();
      } else {
        this.angularFireStorage.ref(imageUrl).delete().subscribe();
      }
    }
  }

  private getDownloadUrl(imageUrl: string, showError: boolean): Observable<any> {
    const ref = this.angularFireStorage.ref(imageUrl);
    return ref.getDownloadURL().pipe(
      tap((url: string) => {
        this.cachedUrl[imageUrl] = url;
      }),
      catchError(err => {
        if (showError) {
          this.toastrService.danger(`Could not fetch file '${imageUrl}'.`, 'Firebase storage');
        }
        return of(null);
      })
    );
  }
}
