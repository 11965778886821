import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { NbWindowRef } from '@nebular/theme';

@Component({
  selector: 'app-error-forcing',
  template: `
    <div class="mb-2">
      <h5>Error message:</h5>
      {{ errorMessage }}
      <h5>General info:</h5>
      You can try to enforce the action and ignore the warning.
    </div>
    <hr>
    <div class="d-flex justify-content-between">
      <button nbButton status="warning" (click)="forceAction()">Force action</button>
      <button nbButton status="danger" (click)="leaveIt()">Leave it</button>
    </div>
  `,
  styles: [``]
})
export class ErrorForcingComponent {

  errorMessage: string;
  forceCallback: (...args: any[]) => Observable<any>;
  forceCallbackArgs?: any[];

  constructor(
    private nbWindowRef: NbWindowRef,
  ) {
  }

  forceAction() {
    if (this.forceCallback) {
      this.forceCallback(...this.forceCallbackArgs).subscribe();
    }
    this.nbWindowRef?.close();
  }

  leaveIt() {
    this.nbWindowRef?.close();
  }
}
