import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbToastrService, NbWindowRef } from '@nebular/theme';
import {
  IMAGE_QUALITY_MAX,
  IMAGE_QUALITY_MIN, IMAGE_RESOLUTION_MAX,
  IMAGE_RESOLUTION_MIN,
  ProfileSettingService
} from '../../service/profile-setting.service';

@Component({
  selector: 'app-image-compression-settings',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSave()">

      <div class="form-group">
        <label for="imageQualityPercentage" class="label">Image compression quality percentage (1-100)</label>
        <input type="number" step="1" nbInput fullWidth id="imageQualityPercentage"
               placeholder="image quality percentage"
               formControlName="imageCompressionQualityPercentage">
      </div>
      <div class="form-group">
        <label for="imageCompressionMaxWidth" class="label">Image compression max width (px)</label>
        <input type="number" step="1" nbInput fullWidth id="imageCompressionMaxWidth"
               placeholder="image quality max width"
               formControlName="imageCompressionMaxWidth">
      </div>
      <div class="form-group">
        <label for="imageCompressionMaxHeight" class="label">Image compression max height (px)</label>
        <input type="number" step="1" nbInput fullWidth id="imageCompressionMaxHeight"
               placeholder="image quality max height"
               formControlName="imageCompressionMaxHeight">
      </div>
      <hr>
      <button nbButton status="primary" [disabled]="!form.valid" type="submit">Save image settings</button>
    </form>
  `,
  styles: [``]
})
export class ImageCompressionSettingsComponent implements OnInit {

  form: FormGroup;

  @Input()
  windowRef: NbWindowRef;

  constructor(
    private formBuilder: FormBuilder,
    private profileSettingService: ProfileSettingService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      imageCompressionQualityPercentage: [this.profileSettingService.imageCompressionQualityPercentage, [Validators.required, Validators.min(IMAGE_QUALITY_MIN), Validators.max(IMAGE_QUALITY_MAX)]],
      imageCompressionMaxWidth: [this.profileSettingService.imageCompressionMaxWidth, [Validators.required, Validators.min(IMAGE_RESOLUTION_MIN), Validators.max(IMAGE_RESOLUTION_MAX)]],
      imageCompressionMaxHeight: [this.profileSettingService.imageCompressionMaxHeight, [Validators.required, Validators.min(IMAGE_RESOLUTION_MIN), Validators.max(IMAGE_RESOLUTION_MAX)]],
    });
  }

  onSave() {
    this.profileSettingService.imageCompressionQualityPercentage = this.form.value.imageCompressionQualityPercentage;
    this.profileSettingService.imageCompressionMaxWidth = this.form.value.imageCompressionMaxWidth;
    this.profileSettingService.imageCompressionMaxHeight = this.form.value.imageCompressionMaxHeight;
    this.toastrService.success('New image compression settings have been applied', 'Image settings');
    this.windowRef?.close();
  }
}
