import { Component } from '@angular/core';
import { NbMenuBag, NbMenuService, NbWindowService } from '@nebular/theme';
import { KeycloakService } from 'keycloak-angular';
import { SettingsComponent } from './common/settings/settings.component';

export const MENU_ITEM_LOGOUT = 'Log out';
export const MENU_ITEM_SETTINGS = 'Settings';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {

  constructor(
    private menuService: NbMenuService,
    private authService: KeycloakService,
    private windowService: NbWindowService,
  ) {
    this.menuService.onItemClick().subscribe((event: NbMenuBag) => {
      switch (event?.item?.title) {
        case MENU_ITEM_LOGOUT:
          this.logout();
          break;
        case MENU_ITEM_SETTINGS:
          this.settings();
          break;
      }
    });
  }

  private logout() {
    this.authService.logout();
  }

  private settings() {
    this.windowService.open(SettingsComponent, {
      title: `Settings`,
      windowClass: 'window-form-wide',
    });
  }
}
