import { Component, OnInit } from '@angular/core';
import { GitInfoService } from '../../../common/service/git-info.service';

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Copyright &copy; Patron
    </span>
    <span *ngIf="gitInfo">
      Version: {{ gitInfo }}
    </span>
    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent implements OnInit {
  gitInfo: string;

  constructor(private gitInfoService: GitInfoService) {
  }

  ngOnInit() {
    this.gitInfoService.getGitInfo().subscribe(
      (gitInfo: string) => this.gitInfo = gitInfo,
    );
  }
}
