import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NbToastrService } from '@nebular/theme';

@Injectable({
    providedIn: 'root',
})
export class GitInfoService {
    private lastGitInfoValue = '';
    private gitInfoSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.lastGitInfoValue);

    constructor(private toastrService: NbToastrService) {
    }

    public getGitInfo(): Observable<string> {
        return this.gitInfoSubject.asObservable();
    }

    public updateGitInfo(gitInfo: string) {
        if (gitInfo) {
            if (gitInfo !== this.lastGitInfoValue && this.lastGitInfoValue !== '') {
                this.toastrService.warning('Version of backend was changed. Please reload page.', 'Backend');
            }
            this.lastGitInfoValue = gitInfo;
            this.gitInfoSubject.next(this.lastGitInfoValue);
        }
    }
}
