import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INPUT_DELAY_MAX, INPUT_DELAY_MIN, ProfileSettingService } from '../../service/profile-setting.service';
import { NbToastrService, NbWindowRef } from '@nebular/theme';

@Component({
  selector: 'app-table-settings',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSave()">
      <div class="form-group">
        <label for="inputDelay" class="label">Table filter inputs delay (millis)</label>
        <input type="number" step="1" nbInput fullWidth id="inputDelay" placeholder="input delay"
               formControlName="inputDelay">
      </div>
      <hr>
      <button nbButton status="primary" [disabled]="!form.valid" type="submit">Save table settings</button>
    </form>
  `,
  styles: [``]
})
export class TableSettingsComponent implements OnInit {

  form: FormGroup;

  @Input()
  windowRef: NbWindowRef;

  constructor(
    private formBuilder: FormBuilder,
    private profileSettingService: ProfileSettingService,
    private toastrService: NbToastrService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      inputDelay: [this.profileSettingService.delay, [Validators.required, Validators.min(INPUT_DELAY_MIN), Validators.max(INPUT_DELAY_MAX)]],
    });
  }

  onSave() {
    this.profileSettingService.delay = this.form.value.inputDelay;
    this.toastrService.success('New table settings have been applied', 'Table settings');
    this.windowRef?.close();
  }
}
