import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

const X_IDM_HEADER = 'X-IDM';
const KEYCLOAK_IDM = 'KEYCLOAK'; // patron administration UI will use just keycloak IDM so we can hardcode it

@Injectable({
  providedIn: 'root',
})
export class IdmInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqWithIdm = req.clone({
      headers: req.headers.set(X_IDM_HEADER, KEYCLOAK_IDM),
    });
    return next.handle(reqWithIdm);
  }
}
