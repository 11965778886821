import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { validHexColor } from '../utils';

// tables keys
const INPUT_DELAY_MILLIS_KEY = 'INPUT_DELAY_MILLIS_KEY';

// image keys
const IMAGE_COMPRESSION_QUALITY_PRECENTAGE_KEY = 'IMAGE_COMPRESSION_QUALITY_PRECENTAGE_KEY';
const IMAGE_COMPRESSION_MAX_WIDHT_KEY = 'IMAGE_COMPRESSION_MAX_WIDHT_KEY';
const IMAGE_COMPRESSION_MAX_HEIGHT_KEY = 'IMAGE_COMPRESSION_MAX_HEIGHT_KEY';

// chatbot keys
const CHATBOT_FINAL_BLOCK_COLOR_KEY = 'CHATBOT_FINAL_BLOCK_COLOR_KEY';
const CHATBOT_FIRST_BLOCK_COLOR_KEY = 'CHATBOT_FIRST_BLOCK_COLOR_KEY';
const CHATBOT_MIDDLE_BLOCK_COLOR_KEY = 'CHATBOT_MIDDLE_BLOCK_COLOR_KEY';
const CHATBOT_TEXT_EDGE_COLOR_KEY = 'CHATBOT_TEXT_EDGE_COLOR_KEY';
const CHATBOT_CHOICE_EDGE_COLOR_KEY = 'CHATBOT_CHOICE_EDGE_COLOR_KEY';
const CHATBOT_FILE_EDGE_COLOR_KEY = 'CHATBOT_FILE_EDGE_COLOR_KEY';
const CHATBOT_RATING_EDGE_COLOR_KEY = 'CHATBOT_RATING_EDGE_COLOR_KEY';

export const INPUT_DELAY_MIN = 200;
export const INPUT_DELAY_MAX = 5000;

export const IMAGE_RESOLUTION_MIN = 100;
export const IMAGE_RESOLUTION_MAX = 8000;

export const IMAGE_QUALITY_MIN = 1;
export const IMAGE_QUALITY_MAX = 100;

export interface ChatbotSettings {
  finalBlockColor: string;
  firstBlockColor: string;
  middleBlockColor: string;
  textEdgeColor: string;
  choiceEdgeColor: string;
  fileEdgeColor: string;
  ratingEdgeColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileSettingService {

  private _delay: number = 500;
  private _imageCompressionQualityPercentage = 50;
  private _imageCompressionMaxWidth = 1200;
  private _imageCompressionMaxHeight = 1200;
  private _finalBlockColor = '#ff9999';
  private _firstBlockColor = '#a4f57d';
  private _middleBlockColor = '#e1e1e1';
  private _textEdgeColor = '#b91e1e';
  private _choiceEdgeColor = '#3e9313';
  private _fileEdgeColor = '#2b55be';
  private _ratingEdgeColor = '#000000';

  private inputFilterDelayChanged: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private _chatbotSettingsChanged: BehaviorSubject<ChatbotSettings>;

  constructor() {
    this.initInputFilterDelay();
    this.initImageCompressionProperties();
    this.initChatbotProperties();
    this._chatbotSettingsChanged = new BehaviorSubject<ChatbotSettings>({
      finalBlockColor: this._finalBlockColor,
      firstBlockColor: this._firstBlockColor,
      middleBlockColor: this._middleBlockColor,
      textEdgeColor: this._textEdgeColor,
      choiceEdgeColor: this._choiceEdgeColor,
      fileEdgeColor: this._fileEdgeColor,
      ratingEdgeColor: this._ratingEdgeColor,
    })
  }

  get delay(): number {
    return this._delay;
  }

  get imageCompressionQualityPercentage(): number {
    return this._imageCompressionQualityPercentage;
  }

  get imageCompressionMaxWidth(): number {
    return this._imageCompressionMaxWidth;
  }

  get imageCompressionMaxHeight(): number {
    return this._imageCompressionMaxHeight;
  }

  get finalBlockColor(): string {
    return this._finalBlockColor;
  }

  get firstBlockColor(): string {
    return this._firstBlockColor;
  }

  get textEdgeColor(): string {
    return this._textEdgeColor;
  }

  get ratingEdgeColor(): string {
    return this._ratingEdgeColor;
  }

  get middleBlockColor(): string {
    return this._middleBlockColor;
  }

  get choiceEdgeColor(): string {
    return this._choiceEdgeColor;
  }

  get fileEdgeColor(): string {
    return this._fileEdgeColor;
  }

  getChatbotSettings(): ChatbotSettings {
    return this._chatbotSettingsChanged.value;
  }

  setChatbotSettings(finalBlock: string, firstBlock: string, middleBlock: string,
                     textEdge: string, choiceEdge: string, fileEdge: string,
                     ratingEdge: string): void {

    if (validHexColor(finalBlock)) {
      this._finalBlockColor = finalBlock;
      localStorage.setItem(CHATBOT_FINAL_BLOCK_COLOR_KEY, finalBlock);
    }
    if (validHexColor(firstBlock)) {
      this._firstBlockColor = firstBlock;
      localStorage.setItem(CHATBOT_FIRST_BLOCK_COLOR_KEY, firstBlock);
    }
    if (validHexColor(middleBlock)) {
      this._middleBlockColor = middleBlock;
      localStorage.setItem(CHATBOT_MIDDLE_BLOCK_COLOR_KEY, middleBlock);
    }
    if (validHexColor(textEdge)) {
      this._textEdgeColor = textEdge;
      localStorage.setItem(CHATBOT_TEXT_EDGE_COLOR_KEY, textEdge);
    }
    if (validHexColor(choiceEdge)) {
      this._choiceEdgeColor = choiceEdge;
      localStorage.setItem(CHATBOT_CHOICE_EDGE_COLOR_KEY, choiceEdge);
    }
    if (validHexColor(fileEdge)) {
      this._fileEdgeColor = fileEdge;
      localStorage.setItem(CHATBOT_FILE_EDGE_COLOR_KEY, fileEdge);
    }
    if (validHexColor(ratingEdge)) {
      this._ratingEdgeColor = ratingEdge;
      localStorage.setItem(CHATBOT_RATING_EDGE_COLOR_KEY, ratingEdge);
    }

    this._chatbotSettingsChanged.next({
      finalBlockColor: this._finalBlockColor,
      firstBlockColor: this._firstBlockColor,
      middleBlockColor: this._middleBlockColor,
      textEdgeColor: this._textEdgeColor,
      choiceEdgeColor: this._choiceEdgeColor,
      fileEdgeColor: this._fileEdgeColor,
      ratingEdgeColor: this._ratingEdgeColor,
    });
  }

  set delay(value: number) {
    if (value === this._delay) return;

    if (value >= INPUT_DELAY_MIN && value <= INPUT_DELAY_MAX) {
      this._delay = value;
      localStorage.setItem(INPUT_DELAY_MILLIS_KEY, value + '');
      this.inputFilterDelayChanged.next();
    }
  }

  set imageCompressionQualityPercentage(value: number) {
    if (value === this._imageCompressionQualityPercentage) return;

    if (value > 0 && value <= 100) {
      this._imageCompressionQualityPercentage = value;
      localStorage.setItem(IMAGE_COMPRESSION_QUALITY_PRECENTAGE_KEY, value + '');
    }
  }

  set imageCompressionMaxWidth(value: number) {
    if (value === this._imageCompressionMaxWidth) return;

    if (value >= IMAGE_RESOLUTION_MIN && value <= IMAGE_RESOLUTION_MAX) {
      this._imageCompressionMaxWidth = value;
      localStorage.setItem(IMAGE_COMPRESSION_MAX_WIDHT_KEY, value + '');
    }
  }

  set imageCompressionMaxHeight(value: number) {
    if (value === this._imageCompressionMaxHeight) return;

    if (value >= IMAGE_RESOLUTION_MIN && value <= IMAGE_RESOLUTION_MAX) {
      this._imageCompressionMaxHeight = value;
      localStorage.setItem(IMAGE_COMPRESSION_MAX_HEIGHT_KEY, value + '');
    }
  }

  getInputFilterDelayChangedSubject(): BehaviorSubject<void> {
    return this.inputFilterDelayChanged;
  }

  get chatbotSettingsChanged(): BehaviorSubject<ChatbotSettings> {
    return this._chatbotSettingsChanged;
  }

  private initInputFilterDelay() {
    this.initNumberKey(INPUT_DELAY_MILLIS_KEY,
      (d) => this._delay = d,
      (d) => typeof d === 'number' && d >= INPUT_DELAY_MIN && d <= INPUT_DELAY_MAX)
  }

  private initImageCompressionProperties() {
    this.initNumberKey(IMAGE_COMPRESSION_QUALITY_PRECENTAGE_KEY,
      (p) => this._imageCompressionQualityPercentage = p,
      (p) => typeof p === 'number' && p >= IMAGE_QUALITY_MIN && p <= IMAGE_QUALITY_MAX);
    this.initNumberKey(IMAGE_COMPRESSION_MAX_WIDHT_KEY,
      (m) => this._imageCompressionMaxWidth = m,
      (m) => typeof m === 'number' && m >= IMAGE_RESOLUTION_MIN && m <= IMAGE_RESOLUTION_MAX);
    this.initNumberKey(IMAGE_COMPRESSION_MAX_HEIGHT_KEY,
      (m) => this._imageCompressionMaxHeight = m,
      (m) => typeof m === 'number' && m >= IMAGE_RESOLUTION_MIN && m <= IMAGE_RESOLUTION_MAX);
  }

  private initChatbotProperties() {
    this.initKey(CHATBOT_FINAL_BLOCK_COLOR_KEY,
      c => this._finalBlockColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_FIRST_BLOCK_COLOR_KEY,
      c => this._firstBlockColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_MIDDLE_BLOCK_COLOR_KEY,
      c => this._middleBlockColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_TEXT_EDGE_COLOR_KEY,
      c => this._textEdgeColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_CHOICE_EDGE_COLOR_KEY,
      c => this._choiceEdgeColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_FILE_EDGE_COLOR_KEY,
      c => this._fileEdgeColor = c,
      c => typeof c === 'string' && validHexColor(c));
    this.initKey(CHATBOT_RATING_EDGE_COLOR_KEY,
      c => this._ratingEdgeColor = c,
      c => typeof c === 'string' && validHexColor(c));

  }

  private initNumberKey(key: string, set: (d: any) => void, validator?: (d: any) => boolean) {
    const localValue = +localStorage.getItem(key);
    if (validator && validator(localValue)) {
      set(localValue);
    }
  }

  private initKey(key: string, set: (d: any) => void, validator?: (d: any) => boolean) {
    const localValue = localStorage.getItem(key);
    if (validator && validator(localValue)) {
      set(localValue);
    }
  }
}
