import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { ApiModule, BASE_PATH, ConfigurationService, FirebaseJwtTokenResponse } from '@patron-administration/api';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {
  NbButtonModule,
  NbDatepickerModule,
  NbDialogModule,
  NbInputModule,
  NbMenuModule,
  NbSidebarModule,
  NbTabsetModule,
  NbTimepickerModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';

import './common/prototypes/custom-prototypes';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { GitInfoInterceptor } from './common/interceptor/git-info.interceptor';
import { IdmInterceptor } from './common/interceptor/idm.interceptor';
import { ThemeModule } from './@theme/theme.module';
import { CoreModule } from './@core/core.module';
import { AppConfigService } from './app-config.service';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { AngularMultiSelectModule } from '@patron-administration/angular2-multiselect-dropdown';
import { NgxEchartsModule } from 'ngx-echarts';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SettingsComponent } from './common/settings/settings.component';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { TableSettingsComponent } from './common/settings/table-settings/table-settings.component';
import {
  ImageCompressionSettingsComponent
} from './common/settings/image-compression-settings/image-compression-settings.component';
import {
  ChatbotEditorSettingsComponent
} from './common/settings/chatbot-editor-settings/chatbot-editor-settings.component';
import { ColorPickerModule } from 'ngx-color-picker';

const keycloakService: KeycloakService = new KeycloakService();

const appInitializerFn = (appConfigService: AppConfigService) => {
  return () => {
    return appConfigService.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    TableSettingsComponent,
    ImageCompressionSettingsComponent,
    ChatbotEditorSettingsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    ApiModule,
    ReactiveFormsModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot({
      duration: 6000
    }),
    NbDateFnsDateModule.forRoot({
      format: 'dd.MM.yyyy',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularMultiSelectModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NbButtonModule,
    NbInputModule,
    QuillModule.forRoot(),
    LMarkdownEditorModule,
    NbTabsetModule,
    ColorPickerModule
  ],
  providers: [
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    {
      provide: BASE_PATH,
      useValue: environment.SERVICE_BASE_URL,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GitInfoInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdmInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
  ],
})
export class AppModule implements DoBootstrap {


  constructor(
    private appConfigService: AppConfigService,
    private configurationService: ConfigurationService,
    private afAuth: AngularFireAuth,
  ) {
    // set Quill to use <b> and <i>, not <strong> and <em>
    const bold = Quill.import('formats/bold');
    bold.tagName = 'b';   // Quill uses <strong> by default
    Quill.register(bold, true);

    const italic = Quill.import('formats/italic');
    italic.tagName = 'i';   // Quill uses <em> by default
    Quill.register(italic, true);
  }

  // initializes keycloak, first page of app is loaded after user successfully logs into keycloak and app gets user's token (oAuth2)
  ngDoBootstrap(appRef: ApplicationRef): void {
    keycloakService
      .init({
        config: {
          clientId: this.appConfigService.getConfig().clientId,
          realm: this.appConfigService.getConfig().realmName,
          url: this.appConfigService.getConfig().authUri,
        },
        initOptions: {
          onLoad: 'login-required',
          enableLogging: true,
        },
        // initializes keycloak built-in HTTP interceptor which adds Bearer user's token to all REST API calls automatically
        // (out of the box solution)
        enableBearerInterceptor: true,
      })
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');
        // generating firebase token to be able to operate with firebase
        this.configurationService.getFirebaseToken().pipe(
          switchMap((tokenResponse: FirebaseJwtTokenResponse) => {
            return from(this.afAuth.signInWithCustomToken(tokenResponse?.jwtToken));
          })
        ).subscribe(u => {
          appRef.bootstrap(AppComponent);
        });
      })
      .catch(error => console.error('[ngBootStrap] init Keycloak failed', error));
  }

}
